<template>
  <div class="w-screen h-screen bg-gray50 justify-center items-center flex flex-col">
    <NuxtLoadingIndicator />
    <h1 v-if="error.statusCode === 404">Page not found</h1>
    <h1
      v-else
      class="text-5xl font-bold"
    >
      An error occurred - {{ error.statusCode }}
    </h1>
    <button
      class="btn bg-black text-white mx-auto w-full max-w-[300px] mt-8"
      @click="$router.go()"
    >
      Reload
    </button>
  </div>
</template>

<script lang="ts">
import { useActivityChecker } from '~/src/composables/utils/useActivityChecker'
const { startCounter } = useActivityChecker()
onMounted(() => startCounter())

export default {
  layout: 'error',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const Router = useRouter()
    const Route = useRoute()

    Router.push(Route.fullPath)
  },
}
</script>
